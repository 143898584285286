"use client"

import { ChakraProvider } from "@chakra-ui/react"
import system from "../../theme/theme"
import { ColorModeProvider, type ColorModeProviderProps } from "./color-mode"

export function Provider(props: ColorModeProviderProps) {
  return (
    <ChakraProvider value={system}>
      <ColorModeProvider enableColorScheme={false} {...props} />
    </ChakraProvider>
  )
}
